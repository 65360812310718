import { useState } from 'react'
import { PriceList } from 'models/IProduct'

export interface ProductProps {
  id: number
  name: string
  image: string
  currency: string
  price: string | null
  priceList: PriceList[] | null
  description: string
  isClickable: boolean
}

export const useProduct = (props: ProductProps) => {
  const { isClickable } = props

  const [showTooltip, setShowTooltip] = useState(false)
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false)

  const handleMouseEnter = () => setShowTooltip(true)
  const handleMouseLeave = () => setShowTooltip(false)

  const handleImageClick = () => {
    if (isClickable) {
      setIsImageViewerOpen(true)
    }
  }
  const handleCloseImageViewer = () => setIsImageViewerOpen(false)

  return {
    ...props,
    showTooltip,
    isImageViewerOpen,
    handleMouseEnter,
    handleMouseLeave,
    handleImageClick,
    handleCloseImageViewer,
  }
}
