import React, { memo } from 'react'
import { useImageViewer, ImageViewerProps } from './useImageViewer'
import styles from './ImageViewer.module.scss'

const ImageViewer: React.FC<ImageViewerProps> = (props: ImageViewerProps) => {
  const { image, handleClose } = useImageViewer(props)

  return (
    <div className={styles['image-viewer']} onClick={handleClose}>
      <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        <img src={image} alt='product large' className={styles.image} />
        <button className={styles['btn-close']} onClick={handleClose}>
          &times;
        </button>
      </div>
    </div>
  )
}

export default memo(ImageViewer)
