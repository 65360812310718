export interface ImageViewerProps {
  image: string
  handleClose: () => void
}

export const useImageViewer = (props: ImageViewerProps) => {
  return {
    ...props,
  }
}
