import React, { memo } from 'react'
import { useProduct, ProductProps } from './useProduct'
import PriceList from './PriceList/PriceList'
import ImageViewer from './ImageViewer/ImageViewer'
import styles from './Product.module.scss'

const Product: React.FC<ProductProps> = (props: ProductProps) => {
  const {
    name,
    currency,
    price,
    priceList,
    image,
    description,
    showTooltip,
    isClickable,
    isImageViewerOpen,
    handleMouseEnter,
    handleMouseLeave,
    handleImageClick,
    handleCloseImageViewer,
  } = useProduct(props)

  return (
    <div
      className={`${styles.product} ${
        isClickable ? styles['is-clickable'] : ''
      }`}
    >
      <img
        src={image}
        alt='product background'
        className={styles.image}
        loading='lazy'
        onClick={handleImageClick}
      />
      <div className='d-flex justify-content-between'>
        <div className={styles.product_name}>
          {name}
          <span
            className={`${styles.tooltip_container} ${
              description ? '' : 'd-none'
            }`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <i className='bi bi-info-circle'></i>
            {showTooltip && <div className={styles.tooltip}>{description}</div>}
          </span>
        </div>
        {price ? (
          <div className={styles.product_price}>
            {currency}
            {price}
          </div>
        ) : (
          priceList &&
          priceList.length && (
            <PriceList currency={currency} priceList={priceList} />
          )
        )}
      </div>
      {isClickable && isImageViewerOpen && (
        <ImageViewer image={image} handleClose={handleCloseImageViewer} />
      )}
    </div>
  )
}

export default memo(Product)
