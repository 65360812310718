import React, { memo } from 'react'
import { useCategory, CategoryProps } from './useCategory'
import styles from './Category.module.scss'

const Category: React.FC<CategoryProps> = (props: CategoryProps) => {
  const { name, isActive, handleCategoryClick } = useCategory(props)

  return (
    <div
      className={`${styles.category} ${isActive ? styles.active : ''}`}
      onClick={handleCategoryClick}
    >
      <span className={styles.category_name}>{name}</span>
    </div>
  )
}

export default memo(Category)
